import { Controller } from "@hotwired/stimulus";
import { ValueDefinitionMap } from "@hotwired/stimulus/dist/types/core/value_properties";
import {
  ChargeSession,
  ChargeSessionStatuses,
} from "../models/charge-session.model";

enum ChargeSessionScreens {
  CONNECT = "connect",
  SESSION = "session",
  END = "end",
  ABANDONED = "abandoned",
  FAILED = "failed"
}

export default class extends Controller {
  static targets: string[] = [
    "infoPower",
    "infoEnergy", 
    "infoDuration", 
    "infoAmount", 
    "stopButton",
    "receiptEnergy",
    "receiptAmount"
  ];

  static values: ValueDefinitionMap = {
    infoEndpoint: String,
    stopEndpoint: String,
    stopping: String,
  };

  declare infoEndpointValue: string;
  declare stopEndpointValue: string;
  declare stoppingValue: string;

  declare infoPowerTarget: HTMLElement;
  declare infoEnergyTarget: HTMLElement;
  declare infoDurationTarget: HTMLElement;
  declare infoAmountTarget: HTMLElement;
  declare stopButtonTarget: HTMLButtonElement;
  declare receiptEnergyTarget: HTMLElement;
  declare receiptAmountTarget: HTMLElement;

  declare screen: ChargeSessionScreens;
  declare timer: number;
  declare timeIntervalID: number;
  declare updateIntervalID: number;

  async connect(): Promise<void> {
    this.checkSession();
    this.updateIntervalID = setInterval(this.checkSession.bind(this), 15000);
  }

  async checkSession(): Promise<void> {
    const response = await fetch(this.infoEndpointValue);
    const data = (await response.json()) as unknown as ChargeSession;
    if (
      this.screen !== ChargeSessionScreens.SESSION &&
      [
        ChargeSessionStatuses.IN_USE,
        ChargeSessionStatuses.FINISHING,
        ChargeSessionStatuses.PENDING_FINISH,
      ].includes(data.status)
    ) {
      this.showScreen(ChargeSessionScreens.SESSION);
      if (
        [
          ChargeSessionStatuses.FINISHING,
          ChargeSessionStatuses.PENDING_FINISH,
        ].includes(data.status)
      ) {
        this.disableStopButton();
      }
      clearInterval(this.updateIntervalID);
      this.updateIntervalID = setInterval(this.checkSession.bind(this), 30000);
    } else if (
      this.screen !== ChargeSessionScreens.END &&
      data.status === ChargeSessionStatuses.FINISHED
    ) {
      this.updateEndScreenInfo(data);
      this.showScreen(ChargeSessionScreens.END, data.invoice);
      clearInterval(this.updateIntervalID);
    } else if (
      this.screen !== ChargeSessionScreens.ABANDONED &&
      data.status === ChargeSessionStatuses.ABANDONED
    ) {
      this.updateEndScreenInfo(data);
      this.showScreen(ChargeSessionScreens.ABANDONED);
      clearInterval(this.updateIntervalID);
    } else if (
      this.screen !== ChargeSessionScreens.FAILED &&
      data.status === ChargeSessionStatuses.FAILED
    ) {
      this.updateEndScreenInfo(data);
      this.showScreen(ChargeSessionScreens.FAILED);
      clearInterval(this.updateIntervalID);
    }

    if (ChargeSessionScreens.SESSION === this.screen) {
      this.updateInfo(data);
      if (!this.timeIntervalID) {
        this.timer = data.duration;
        this.timeIntervalID = setInterval(this.updateTimer.bind(this), 1000);
      }
    }

    if (ChargeSessionScreens.SESSION !== this.screen && this.timeIntervalID) {
      clearInterval(this.timeIntervalID);
      this.timeIntervalID = 0;
    }
  }

  updateEndScreenInfo(data: ChargeSession) {
    this.receiptEnergyTarget.textContent = data.transmittedEnergy.replace(".", ",");
    this.receiptAmountTarget.textContent = data.amount.replace(".", ",");
  }

  updateTimer() {
    this.timer += 1;
    this.infoDurationTarget.textContent = this.formatDuration(this.timer);
  }

  updateInfo(data: ChargeSession) {
    this.infoPowerTarget.textContent = data.power.replace(".", ",");
    this.infoEnergyTarget.textContent = data.transmittedEnergy.replace(".", ",");
    this.infoDurationTarget.textContent = this.formatDuration(data.duration);
    this.infoAmountTarget.textContent = data.amount.replace(".", ",");
  }

  formatDuration(duration: number): string | null {
    if (duration === 0) {
      return null;
    }
    const hours = Math.floor(duration / 3600).toString().padStart(2, "0");
    const minutes = Math.floor((duration % 3600) / 60).toString().padStart(2, "0");
    const seconds = (duration % 60).toString().padStart(2, "0");
    return `${hours}:${minutes}:${seconds}`;
  }
  showScreen(screen: ChargeSessionScreens, invoice: string|null = null): void {
    this.hideScreens(screen);
    document
      .querySelector(`[data-screen="${screen}"]`)
      ?.classList.remove("u-hidden");
    this.screen = screen;

    if (invoice !== null) {
        const btnInvoice = document.getElementsByClassName("btn-invoice");
        btnInvoice[0]?.classList.remove("u-hidden");
    }
  }

  async stop(): Promise<void> {
    try {
      const response = await fetch(this.stopEndpointValue, {
        method: "POST",
      });
      if (!response.ok) {
        throw new Error("Failed to stop charge session");
      }
      clearInterval(this.timeIntervalID);
      setTimeout(this.checkSession.bind(this), 1000);
      this.timeIntervalID = setInterval(this.checkSession.bind(this), 5000);
      this.disableStopButton();
    } catch (error) {
      console.error(error);
    }
  }

  private disableStopButton() {
    this.stopButtonTarget.disabled = true;
    this.stopButtonTarget.textContent = this.stoppingValue;
  }

  private hideScreens(exclude?: ChargeSessionScreens): void {
    document.querySelectorAll("[data-screen]").forEach((screen) => {
      const screenName = screen.getAttribute("data-screen");
      if (screenName !== exclude) {
        screen.classList.add("u-hidden");
      }
    });
  }
}
