import { Controller } from "@hotwired/stimulus";
import { ValueDefinitionMap } from "@hotwired/stimulus/dist/types/core/value_properties";

export default class extends Controller {
  static targets: string[] = [
    "form",
    "email",
    "emailLabel",
    "chargerLabel",
    "amountLabel",
    "noLimitRadio",
    "listAmount",
    "citySelect",
    "parkingSelect",
    "chargersSection",
    "requestInvoice",
    "requestInvoiceBlock",
    "name",
    "lastName",
    "address",
    "numberDocument",
    "postalCode",
    "municipality",
    "submitButton",
  ];

  static values: ValueDefinitionMap = {
    selectManual: Boolean,
    urlCities: String,
    urlParking: String,
  };

  declare readonly formTarget: HTMLFormElement;
  declare readonly emailTarget: HTMLInputElement;
  declare readonly emailLabelTarget: HTMLLabelElement;
  declare readonly chargerLabelTarget: HTMLLabelElement;
  declare readonly amountLabelTarget: HTMLLabelElement;
  declare readonly noLimitRadioTarget: HTMLInputElement;
  declare readonly listAmountTarget: HTMLInputElement;
  declare readonly citySelectTarget: HTMLSelectElement;
  declare readonly parkingSelectTarget: HTMLSelectElement;
  declare readonly chargersSectionTarget: HTMLUListElement;

  declare readonly requestInvoiceTarget: HTMLInputElement;
  declare readonly requestInvoiceBlockTarget: HTMLDivElement;
  declare readonly nameTarget: HTMLInputElement;
  declare readonly lastNameTarget: HTMLInputElement;
  declare readonly addressTarget: HTMLInputElement;
  declare readonly numberDocumentTarget: HTMLInputElement;
  declare readonly postalCodeTarget: HTMLInputElement;
  declare readonly municipalityTarget: HTMLInputElement;

  declare readonly submitButtonTarget: HTMLInputElement;

  declare selectManualValue: boolean;
  declare urlCitiesValue: string;
  declare urlParkingValue: string;

  declare cities: Array<any>;

  connect(): void {
    this.requestInvoiceBlockTarget.classList.add("u-hidden");
    this.renderCities();
  }

  onChangeAmount(event: Event) {
    const target = event.target as HTMLInputElement;
    if (target.value) {
      this.noLimitRadioTarget.checked = false;
    }
    if (this.amountLabelTarget.classList.contains("o-text--danger")) {
      this.amountLabelTarget.classList.remove("o-text--danger");
    }
  }

  onNoLimitChange(event: Event) {
    const target = event.target as HTMLInputElement;
    if (target.checked) {
      this.listAmountTarget
        .querySelectorAll('input[type="radio"]')
        .forEach((input) => {
          (input as HTMLInputElement).checked = false;
        });
    }
    if (this.amountLabelTarget.classList.contains("o-text--danger")) {
      this.amountLabelTarget.classList.remove("o-text--danger");
    }
  }

  onRequestInvoiceChange(event: Event) {
    const target = event.target as HTMLInputElement;
    if (target.checked) {
      this.requestInvoiceBlockTarget.classList.remove("u-hidden");
      target.value = "true";
    } else {
      this.requestInvoiceBlockTarget
        .querySelectorAll('input[type="text"]')
        .forEach((input) => {
          (input as HTMLInputElement).required = false;
          (input as HTMLInputElement).value = "";
        });
      this.requestInvoiceBlockTarget.classList.add("u-hidden");
      target.value = "false";
    }
  }

  submit(event: Event) {
    event.preventDefault();
    this.hiddenErrors();
    
    const numberDocumentInput = this.numberDocumentTarget.querySelector('input[type="text"]') as HTMLInputElement;
    numberDocumentInput.value = numberDocumentInput.value.toUpperCase();

    const formData = new FormData(this.formTarget);

    if (!this.isValidForm(formData)) {
      this.displayErrors(formData);
      this.doScrollToError(formData);
      return;
    }
    if (this.selectManualValue !== false) {
      const chargerUuid = formData.get("charger") as string;
      const chargerShortCode = formData.get(chargerUuid) as string;
      let url = this.formTarget
        .getAttribute("action")
        ?.replace("__id__", chargerShortCode);
      this.formTarget.setAttribute("action", url !== undefined ? url : "");
    }
    this.submitButtonTarget.setAttribute('disabled', 'true')
    this.formTarget.submit();
  }

  onEmailKeyUp() {
    const formData = new FormData(this.formTarget);
    const email = formData.get("email") as string;
    if (this.isValidEmail(email)) {
      this.emailTarget.classList.remove("has-error");
      this.emailLabelTarget.classList.remove("o-text--danger");
    } else {
      this.emailTarget.classList.add("has-error");
      this.emailLabelTarget.classList.add("o-text--danger");
    }
  }

  isValidEmail(email: string): boolean {
    return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);
  }

  isValidCharger(charger: string): boolean {
    return charger?.length > 0;
  }

  isValidFieldRequired(field: string): boolean {
    return field?.length > 0;
  }

  onChargerChange() {
    if (this.chargerLabelTarget.classList.contains("o-text--danger")) {
      this.chargerLabelTarget.classList.remove("o-text--danger");
    }
  }

  private hiddenErrors() {
    this.emailTarget.classList.remove("has-error");
    this.emailLabelTarget.classList.remove("o-text--danger");
    this.chargerLabelTarget.classList.remove("o-text--danger");
    this.amountLabelTarget.classList.remove("o-text--danger");
    this.nameTarget.classList.remove("has-error");
    this.lastNameTarget.classList.remove("has-error");
    this.addressTarget.classList.remove("has-error");
    this.postalCodeTarget.classList.remove("has-error");
    this.numberDocumentTarget.classList.remove("has-error");
    this.municipalityTarget.classList.remove("has-error");

    const divCity = document.querySelector("div.select-city") as HTMLDivElement;
    divCity.classList.remove("has-error");
    const labelCity = divCity.querySelector("label");
    labelCity?.classList.remove("o-text--danger");
    
    const divParking = document.querySelector("div.select-parking") as HTMLDivElement;
    divParking.classList.remove("has-error");
    const labelParking = divParking.querySelector("label");
    labelParking?.classList.remove("o-text--danger");
  }

  private doScrollToError(formData: FormData) {
    const city = formData.get("city") as string;
    const parking = formData.get("parking") as string;
    const email = formData.get("email") as string;
    const charger = formData.get("charger") as string;
    const maxAmount = formData.get("maxAmount") as string;
    const noLimits = formData.get("noLimits") as string;
    const name = formData.get("name") as string;
    const lastName = formData.get("lastName") as string;
    const address = formData.get("address") as string;
    const postalCode = formData.get("postalCode") as string;
    const numberDocument = formData.get("numberDocument") as string;
    const municipality = formData.get("municipality") as string;

    if (this.selectManualValue !== false) {
      if (city === null || (typeof city === "string" && city.length === 0)) {
        this.citySelectTarget.scrollIntoView({
          behavior: "smooth",
          block: "center",
        });
        return;
      }

      if (parking === null || (typeof parking === "string" && parking.length === 0)) {
        this.parkingSelectTarget.scrollIntoView({
          behavior: "smooth",
          block: "center",
        });
        return;
      }
    }

    if (!this.isValidEmail(email)) {
      this.emailTarget.scrollIntoView({ behavior: "smooth", block: "center" });
      return;
    }

    if (this.requestInvoiceTarget.checked === true) {
      if (!this.isValidFieldRequired(name)) {
        this.nameTarget.scrollIntoView({
          behavior: "smooth",
          block: "center",
        });
        return;
      }
      if (!this.isValidFieldRequired(lastName)) {
        this.lastNameTarget.scrollIntoView({
          behavior: "smooth",
          block: "center",
        });
        return;
      }
      if (!this.isValidFieldRequired(address)) {
        this.addressTarget.scrollIntoView({
          behavior: "smooth",
          block: "center",
        });
        return;
      }
      if (!this.isValidFieldRequired(postalCode)) {
        this.postalCodeTarget.scrollIntoView({
          behavior: "smooth",
          block: "center",
        });
        return;
      }
      if (!this.isValidNumberDocument(numberDocument)) {
        this.numberDocumentTarget.scrollIntoView({
          behavior: "smooth",
          block: "center",
        });
        return;
      }
      if (!this.isValidFieldRequired(municipality)) {
        this.municipalityTarget.scrollIntoView({
          behavior: "smooth",
          block: "center",
        });
        return;
      }
    }

    if (!this.isValidCharger(charger)) {
      this.chargerLabelTarget.scrollIntoView({
        behavior: "smooth",
        block: "center",
      });
      return;
    }

    if (!this.isValidAmount(maxAmount, noLimits)) {
      this.amountLabelTarget.scrollIntoView({
        behavior: "smooth",
        block: "center",
      });
      return;
    }
  }

  private displayErrors(formData: FormData) {
    const city = formData.get("city") as string;
    const parking = formData.get("parking") as string;
    const email = formData.get("email") as string;
    const charger = formData.get("charger") as string;
    const maxAmount = formData.get("maxAmount") as string;
    const noLimits = formData.get("noLimits") as string;
    const name = formData.get("name") as string;
    const lastName = formData.get("lastName") as string;
    const address = formData.get("address") as string;
    const postalCode = formData.get("postalCode") as string;
    const numberDocument = formData.get("numberDocument") as string;
    const municipality = formData.get("municipality") as string;

    if (this.selectManualValue !== false) {
      if (city === null || (typeof city === "string" && city.length === 0)) {
        const divCity = document.querySelector("div.select-city") as HTMLDivElement;
        divCity.classList.add("has-error");
        const labelCity = divCity.querySelector("label");
        labelCity?.classList.add("o-text--danger");
      }

      if (parking === null || (typeof parking === "string" && parking.length === 0)) {
        const divParking = document.querySelector("div.select-parking") as HTMLDivElement;
        divParking.classList.add("has-error");
        const labelParking = divParking.querySelector("label");
        labelParking?.classList.add("o-text--danger");
      }
    }

    if (!this.isValidEmail(email)) {
      this.emailTarget.classList.add("has-error");
      this.emailLabelTarget.classList.add("o-text--danger");
    }

    if (!this.isValidCharger(charger)) {
      this.chargerLabelTarget.classList.add("o-text--danger");
    }

    if (!this.isValidAmount(maxAmount, noLimits)) {
      this.amountLabelTarget.classList.add("o-text--danger");
    }

    if (this.requestInvoiceTarget.checked === true) {
      if (!this.isValidFieldRequired(name)) {
        this.nameTarget.classList.add("has-error");
      }
      if (!this.isValidFieldRequired(lastName)) {
        this.lastNameTarget.classList.add("has-error");
      }
      if (!this.isValidFieldRequired(address)) {
        this.addressTarget.classList.add("has-error");
      }
      if (!this.isValidFieldRequired(postalCode)) {
        this.postalCodeTarget.classList.add("has-error");
      }
      if (!this.isValidNumberDocument(numberDocument)) {
        this.numberDocumentTarget.classList.add("has-error");
      }
      if (!this.isValidFieldRequired(municipality)) {
        this.municipalityTarget.classList.add("has-error");
      }
    }
  }

  private isValidAmount(maxAmount: string, noLimits: string): boolean {
    if (!maxAmount && noLimits !== "on") {
      return false;
    }
    return true;
  }

  private isValidNumberDocument(field: string) {
    if (!this.isValidFieldRequired(field)) {
        return false;
    }
    return this.isValidDNI(field) || this.isValidNIE(field) || this.isValidCIF(field);
  }

  private isValidDNI(dni: string): boolean {
    const letters = "TRWAGMYFPDXBNJZSQVHLCKE";
    const letter = letters.charAt(parseInt(dni, 10) % 23);
    
    return letter === dni.charAt(8);
  };

  private isValidNIE(nie: string): boolean {
    const letter = nie.charAt(0);
    let niePrefix = null;

    switch (letter) {
      case 'X': niePrefix = 0; break;
      case 'Y': niePrefix = 1; break;
      case 'Z': niePrefix = 2; break;
    }

    if (niePrefix === null) {
        return false;
    }

    return this.isValidDNI(`${niePrefix}${nie.substring(1)}`);
  };

  private isValidCIF(cif: string): boolean {
    const CIF_REGEX = /^([ABCDEFGHJKLMNPQRSUVW])(\d{7})([0-9A-J])$/;
    const match = cif.match(CIF_REGEX);

    if (match === null) {
      return false;
    }

    const letters = ['J', 'A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I'];
    const digits = cif.substring(1, cif.length - 1);
    const letter = cif.substring(0, 1);
    const control = cif.substring(cif.length - 1);
    let sum = 0;
    let i;
    let digit;

    if (!letter.match(/[A-Z]/)) {
      return false;
    }

    for (i = 0; i < digits.length; ++i) {
      digit = parseInt(digits[i]);

      if (isNaN(digit)) {
        return false;
      }

      if (i % 2 === 0) {
        digit *= 2;
        if (digit > 9) {
          digit = parseInt((digit / 10).toString()) + (digit % 10);
        }

        sum += digit;
      } else {
        sum += digit;
      }
    }

    sum %= 10;
    if (sum !== 0) {
      digit = 10 - sum;
    } else {
      digit = sum;
    }

    if (letter.match(/[ABEH]/)) {
      return String(digit) === control;
    }
    if (letter.match(/[NPQRSW]/)) {
      return letters[digit] === control;
    }

    return String(digit) === control || letters[digit] === control;
  }

  private isValidRequestInvoice(formData: FormData): boolean {
    if (this.requestInvoiceTarget.checked === false) {
      return true;
    }
    const name = formData.get("name") as string;
    const lastName = formData.get("lastName") as string;
    const address = formData.get("address") as string;
    const postalCode = formData.get("postalCode") as string;
    const numberDocument = formData.get("numberDocument") as string;
    const municipality = formData.get("municipality") as string;

    if (!this.isValidFieldRequired(name)) {
      return false;
    }
    if (!this.isValidFieldRequired(lastName)) {
      return false;
    }
    if (!this.isValidFieldRequired(address)) {
      return false;
    }
    if (!this.isValidFieldRequired(postalCode)) {
      return false;
    }
    if (!this.isValidNumberDocument(numberDocument)) {
      return false;
    }
    if (!this.isValidFieldRequired(municipality)) {
      return false;
    }

    return true;
  }

  private isValidForm(formData: FormData): boolean {
    const city = formData.get("city") as string;
    const parking = formData.get("parking") as string;
    const email = formData.get("email") as string;
    const charger = formData.get("charger") as string;
    const maxAmount = formData.get("maxAmount") as string;
    const noLimits = formData.get("noLimits") as string;

    if (this.selectManualValue !== false) {
      if (city === null || (typeof city === "string" && city.length === 0)) {
        return false;
      }

      if (parking === null || (typeof parking === "string" && parking.length === 0)) {
        return false;
      }
    }

    if (!this.isValidEmail(email)) {
      return false;
    }

    if (!this.isValidCharger(charger)) {
      return false;
    }

    if (!this.isValidAmount(maxAmount, noLimits)) {
      return false;
    }

    if (!this.isValidRequestInvoice(formData)) {
      return false;
    }

    return true;
  }

  async renderCities() {
    if (this.selectManualValue !== false) {
      try {
        const response = await fetch(this.urlCitiesValue, {
          method: "GET",
        });
        if (!response.ok) {
          throw new Error("Failed to stop charge session");
        }
        const result = await response.json();
        this.cities = result;
        this.refreshDropdownCitiesValues(result);
      } catch (error) {
        console.error(error);
      }
    }
  }

  refreshDropdownCitiesValues(data: any) {
    this.citySelectTarget.removeAttribute("disabled");
    this.citySelectTarget.innerHTML = "";
    this.citySelectTarget.innerHTML += "<option></option>";
    for (var i = 0; i < data.length; i++) {
      var city = data[i];
      this.citySelectTarget.innerHTML +=
        '<option value="' + city.uuid + '">' + city.name + "</option>";
    }
  }

  onCityChange() {
    const cityUuid = this.citySelectTarget.value;
    if (cityUuid.length > 0) {
      const city: any = this.cities.find((city) => city.uuid === cityUuid);
      this.refreshDropdownParkingValues(city.parkings);
      this.chargersSectionTarget.innerHTML = "";
    } else {
      this.parkingSelectTarget.innerHTML = "";
    }
  }
  refreshDropdownParkingValues(data: any) {
    this.parkingSelectTarget.removeAttribute("disabled");
    this.parkingSelectTarget.innerHTML = "";
    this.parkingSelectTarget.innerHTML += "<option></option>";
    for (var i = 0; i < data.length; i++) {
      var parking = data[i];
      this.parkingSelectTarget.innerHTML +=
        '<option value="' + parking.uuid + '">' + parking.name + "</option>";
    }
  }

  async onParkingChange() {
    const parkingUuid = this.parkingSelectTarget.value;
    if (parkingUuid.length > 0) {
      try {
        const response = await fetch(
          this.urlParkingValue.replace("_id_", parkingUuid),
          {
            method: "GET",
          }
        );
        if (!response.ok) {
          throw new Error("Failed to stop charge session");
        }
        const result = await response.text();
        this.renderChargers(result);
      } catch (error) {
        console.error(error);
      }
    } else {
      this.chargersSectionTarget.innerHTML = "";
    }
  }

  renderChargers(data: any) {
    this.chargersSectionTarget.innerHTML = "";
    this.chargersSectionTarget.innerHTML = data;
  }
}
